import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import SEO from "../components/seo";
import ProjectArticle from "../components/ProjectArticle";

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      data {
        ...project
      }
    }
  }
`;

const PageProject = ({ data }) => {
  const { meta_title, meta_description, meta_image, title, texte, images } =
    data.prismicProject.data;

  // useEffect(() => {}, []);
  // console.log(meta_title);
  // console.log(images);
  const posterDefault = images[0].image.url;
  // console.log(posterDefault);
  return (
    <div className='wrapper'>
      <SEO
        pageTitle={meta_title ? meta_title : title.text}
        pageDescription={
          meta_description ? meta_description.text : "desc google here"
        }
        pageBanner={
          meta_image && meta_image.url ? meta_image.url : posterDefault
        }
        template={`template-project`}
        page={true}
      />
      <div className='content md:px-md'>
        <ProjectArticle input={data.prismicProject.data} />
      </div>
    </div>
  );
};

export default withPreview(PageProject);
